/* eslint-disable no-param-reassign,indent,no-unused-expressions */
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { stringify } from 'qs';
import { getField, updateField } from 'vuex-map-fields';
import router from '../router';

Vue.use(Vuex);

// https://github.com/vuejs/vuex/issues/598

export default new Vuex.Store({
    strict: false,
    /**
     * This is where you define the data structure of your application.
     * You can also set default or initial state here.
     */
    state: {
        filter: {
            filterDesignation: '',
            filterText: '',
            filterConcept: '',
            filterCanton: '',
        },
        sameAsBilling: true,
        currentUser: {
            customerInfo: {},
            billingAddress: {
                title: null,
                lastName: '',
                firstName: '',
                address1: '',
                address2: '',
                city: '',
                zipCode: '',
                countryId: null,
            },
            shippingAddress: {
                title: null,
                lastName: '',
                firstName: '',
                address1: '',
                address2: '',
                city: '',
                zipCode: '',
                countryId: null,
            },
            addresses: [],
            gdprStatus: false,
            termsStatus: false,
        },
        order: {},
        gateways: [],
        cart: {
            lineItems: {},
            orderAdjustments: {},
        },
        entries: [],
        currentView: '',
        pagination: {},
        products: [],
        isLoading: true,
        craftErrors: {},
        axiosGetSuccess: false,
        axiosPostSuccess: false,
        csrf: {},
    },
    /**
     * The mutations calls are the only place that the store can be updated.
     */
    mutations: {
        updateField,
        SET_IS_LOADING(state, isLoading) {
            state.isLoading = isLoading;
        },
        SET_CART(state, cart) {
            // cart.data.cart.gatewayId = cart.data.cart.gatewayId === '6' ? '3' : cart.data.cart.gatewayId;
            state.cart = cart.data.cart;
        },
        SET_ORDER(state, order) {
            state.order = order.data.data.reduce((obj, item) => item, {});
        },
        SET_PRODUCTS(state, products) {
            state.products = products.data.data;
        },
        SET_ENTRIES(state, entries) {
            state.entries = entries.data.data;
            // state.pagination = entries.data.meta.pagination;
        },
        SET_CURRENT_USER(state, currentUser) {
            state.currentUser = currentUser.data.data.reduce(
                (obj, item) => item,
                {},
            );
        },
        SET_GATEWAYS(state, gateways) {
            // remove donation gateway
            state.gateways = gateways.data.data[0].filter(
                obj => obj.id !== '6',
            );
        },
        SET_ERRORS(state, error) {
            state.craftErrors = error;
        },
        SET_AXIOS_GET_SUCCESS(state, success) {
            state.axiosGetSuccess = success;
        },
        SET_AXIOS_POST_SUCCESS(state, success) {
            state.axiosPostSuccess = success;
        },
        SET_CURRENT_VIEW(state, view) {
            state.currentView = view;
        },
        SET_CSRF(state, csrf) {
            state.csrf = csrf.data;
        },
    },
    /**
     * Actions are where you define the calls that will commit changes to your store.
     * A common example of this would be a call to an api to retrieve data, once it completes you call
     * store.commit() to invoke a mutation to actually update the data in the store.
     * Actions are called in your components via dispatch call.
     */
    actions: {
        // axios
        async axiosGet({ commit }, payload) {
            commit('SET_AXIOS_GET_SUCCESS', false);
            await axios({
                method: 'GET',
                url: payload.current_page
                    ? `${payload.api}?page=${payload.current_page}`
                    : payload.api,
            })
                .then(response => {
                    if (
                        response.status !== 200 ||
                        response.data.error ||
                        // eslint-disable-next-line no-prototype-builtins
                        (response.data.hasOwnProperty('success') &&
                            !response.data.success)
                    ) {
                        if (process.env.NODE_ENV !== 'production') {
                            console.log('axiosGet error: ', response);
                        }
                        commit(
                            'SET_ERRORS',
                            // eslint-disable-next-line no-nested-ternary
                            response.data.errors
                                ? response.data.errors
                                : response.data.error
                                ? response.data.error
                                : response.data.message,
                        );
                        commit('SET_AXIOS_GET_SUCCESS', false);
                        return;
                    }
                    payload.mutation && commit(payload.mutation, response);
                    payload.routerGo && router.go(payload.routerGo);
                    payload.routerPush && router.push(payload.routerPush);
                    if (process.env.NODE_ENV !== 'production') {
                        console.log(
                            `payload: ${JSON.stringify(
                                payload,
                            )}\naxiosGet response:\n`,
                            response,
                        );
                    }
                    commit('SET_AXIOS_GET_SUCCESS', true);
                })
                .catch(error => {
                    console.error(error);
                    commit('SET_AXIOS_GET_SUCCESS', false);
                });
        },
        async axiosPost({ commit }, payload) {
            commit('SET_AXIOS_POST_SUCCESS', false);
            await axios({
                method: 'POST',
                url: payload.api,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-Token': this.state.csrf.value,
                },
                data: stringify(payload.data),
            })
                .then(response => {
                    if (
                        response.status !== 200 ||
                        response.data.error ||
                        // eslint-disable-next-line no-prototype-builtins
                        (response.data.hasOwnProperty('success') &&
                            !response.data.success)
                    ) {
                        if (process.env.NODE_ENV !== 'production') {
                            console.log('axiosPost error: ', response);
                        }
                        commit(
                            'SET_ERRORS',
                            // eslint-disable-next-line no-nested-ternary
                            response.data.errors
                                ? response.data.errors
                                : response.data.error
                                ? response.data.error
                                : response.data.message,
                        );
                        return;
                    }
                    payload.mutation && commit(payload.mutation, response);
                    payload.routerGo && router.go(payload.routerGo);
                    payload.routerPush && router.push(payload.routerPush);
                    if (process.env.NODE_ENV !== 'production') {
                        console.log(
                            `payload: ${JSON.stringify(
                                payload,
                            )}\naxiosPost response:\n`,
                            response,
                        );
                    }
                    commit('SET_AXIOS_POST_SUCCESS', true);
                })
                .catch(error => {
                    console.error(error);
                    commit('SET_AXIOS_POST_SUCCESS', false);
                });
        },
        async setMutation({ commit }, payload) {
            await commit(payload.mutation, payload.value);
        },
    },
    /**
     * Getters are a way to grab computed data from the store.
     * For example, if you have a project list, one component might only want to
     * show projects that are completed:
     */
    getters: {
        getField,
        lineItems: state => {
            return state.cart.lineItems;
        },
        filteredEntries: state => {
            const filterDesignation = state.filter.filterDesignation.trim();
            const filterText = state.filter.filterText.toLowerCase().trim();
            const filterConcept = state.filter.filterConcept
                .toLowerCase()
                .trim();
            const filterCanton = state.filter.filterCanton.toLowerCase().trim();

            if (
                filterDesignation === '' &&
                !filterText &&
                !filterConcept &&
                !filterCanton
            ) {
                if (state.entries.find(e => e.section !== 'team')) {
                    return state.entries.sort((a, b) =>
                        a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1,
                    );
                }
                return state.entries;
            }

            const entries = state.entries.filter(
                e =>
                    e.related.toLowerCase().indexOf(filterConcept) > -1 &&
                    e.canton.toLowerCase().indexOf(filterCanton) > -1 &&
                    e.title.toLowerCase().indexOf(filterText) > -1 &&
                    e.designation.indexOf(filterDesignation) > -1,
            );

            if (state.entries.find(e => e.section !== 'team')) {
                return entries.sort((a, b) =>
                    a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1,
                );
            }
            return entries;
        },
        itemsInCart: state => {
            let sum = 0;
            Object.keys(state.cart.lineItems).forEach(key => {
                // don't sum up manual donations
                if (
                    state.cart.lineItems[key].price === '0.0100' ||
                    state.cart.lineItems[key].price === 0.01
                ) {
                    sum += 1;
                } else {
                    sum += parseInt(state.cart.lineItems[key].qty, 10);
                }
            });
            return sum;
        },
        sortedAdjustments: state => {
            // create new object from array
            // const arrayToObject = array =>
            //     array.reduce((obj, item) => item, {});
            // add each object to a new object
            // const obj = {};
            // Object.keys(state.cart.orderAdjustments).forEach(array => {
            //     obj[array] = arrayToObject(state.cart.orderAdjustments[array]);
            // });
            // sort object
            // const sorted = {};
            // Object.keys(obj)
            //     .sort((a, b) => {
            //         return obj[b].id - obj[a].id;
            //     })
            //     .forEach(key => {
            //         sorted[key] = obj[key];
            //     });
            // return sorted;
            return state.cart.orderAdjustments;
        },
        currentGateway: state => {
            return state.gateways.filter(
                obj => obj.id === state.cart.gatewayId,
            );
        },
    },
});
