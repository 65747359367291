import Vue from 'vue';
import Router from 'vue-router';

const CheckoutCart = () => import('../views/CheckoutCart.vue');
const CheckoutAddresses = () => import('../views/CheckoutAddresses.vue');
const CheckoutPayment = () => import('../views/CheckoutPayment.vue');
const CheckoutOrder = () => import('../views/CheckoutOrder.vue');

Vue.use(Router);

export default new Router({
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        return { x: 0, y: 0 };
    },
    // mode: 'history',
    routes: [
        {
            path: '/cart',
            name: 'checkout cart',
            component: CheckoutCart,
        },
        {
            path: '/addresses',
            name: 'checkout addresses',
            component: CheckoutAddresses,
        },
        {
            path: '/payment',
            name: 'checkout payment',
            component: CheckoutPayment,
        },
        {
            path: '/order',
            name: 'checkout order',
            component: CheckoutOrder,
        },
        { path: 'shop/checkout#/cart', redirect: { name: 'checkout cart' } },
    ],
});

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};
