// We need to include ie11 polyfills used by webpack dynamic import
// because webpack generated code does not go through babel

import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.iterator';

import store from './vue/store';
import router from './vue/router';
import { mapActions, mapGetters, mapState } from 'vuex';
import getCurrentLanguage from './helpers/getCurrentLanguage';
import VueFlashMessage from 'vue-flash-message';
import VueMoment from 'vue-moment';
import VueProgressBar from 'vue-progressbar';
import VueCurrencyFilter from 'vue-currency-filter';
import vSelect from 'vue-select';

/**
 * Bugglyfill mobile safari navbar-hacks

const hacks = require('viewport-units-buggyfill/viewport-units-buggyfill.hacks');
require('viewport-units-buggyfill').init({
    hacks,
});
 */
/*
 Common JS that will be loaded on every page
 */
const app = async () => {
    // Async load LazySizes and it's plugins
    const LazySizes = await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes'
    );
    await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes/plugins/respimg/ls.respimg'
    );
    await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes/plugins/parent-fit/ls.parent-fit'
    );
    await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes/plugins/object-fit/ls.object-fit'
    );
    await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes/plugins/attrchange/ls.attrchange'
    );

    // fix issue when image is already in viewport and content is not loaded yet
    LazySizes.cfg.init = false;

    await import(/* webpackChunkName: "cookieBar" */ './modules/cookie');

    // Async load the vue module

    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

    const VeeValidate = await import(
        /* webpackChunkName: "veeValidate" */ 'vee-validate'
    );

    const {
        localize,
        ValidationProvider,
        extend,
        ValidationObserver,
    } = await import(/* webpackChunkName: "veeValidate" */ 'vee-validate');
    const { required, email } = await import(
        /* webpackChunkName: "veeValidate" */ 'vee-validate/dist/rules'
    );

    const lang = document.documentElement.lang.substr(0, 2);

    await import(
        `vee-validate/dist/locale/${lang}.json` /* webpackInclude: /(de|en|fr).json$/ */ /* webpackChunkName: "locale-[index]-[request]" */
    ).then(locale => {
        localize(lang, locale);
    });

    extend('email', email);

    extend('required', {
        ...required,
    });

    Vue.component('ValidationProvider', ValidationProvider);
    Vue.component('ValidationObserver', ValidationObserver);
    Vue.component('v-select', vSelect);

    Vue.use(VeeValidate);

    Vue.use(VueProgressBar, {
        color: '#3abad9',
        failedColor: '#fa0f38',
        thickness: '2px',
        transition: {
            speed: '0.6s',
            opacity: '0.6s',
            termination: 300,
        },
        autoRevert: true,
        location: 'top',
        position: 'relative',
        inverse: false,
    });

    Vue.use(VueCurrencyFilter, {
        symbol: 'CHF',
        thousandsSeparator: '',
        fractionCount: 2,
        fractionSeparator: '.',
        symbolPosition: 'front',
    });

    Vue.use(VueFlashMessage);
    Vue.use(VueMoment);

    // eslint-disable-next-line no-unused-vars
    const vm = new Vue({
        name: 'app',
        store,
        router,
        el: '#app',
        delimiters: ['${', '}'],
        async created() {
            await this.axiosGet({
                api: '/actions/wfw-plugin/token/get-csrf',
                mutation: 'SET_CSRF',
            });
            // get cart TODO only on product page or similar
            await this.axiosGet({
                mutation: 'SET_CART',
                api: `${getCurrentLanguage()}/actions/commerce/cart/get-cart`,
            });
            // subscribe for error messages
            this.$store.subscribe((mutation, state) => {
                if (mutation.type === 'SET_ERRORS') {
                    // cart error messages
                    if (typeof state.craftErrors === 'object') {
                        const errorMessage =
                            state.craftErrors[
                                Object.keys(state.craftErrors)[0]
                            ];
                        this.flash(errorMessage, 'error', {
                            timeout: 7000,
                        });
                    } else {
                        const errorMessage = state.craftErrors;
                        this.flash(errorMessage, 'error', {
                            timeout: 7000,
                        });
                    }
                }
            });
        },
        computed: {
            ...mapState(['cart', 'craftErrors']),
            ...mapGetters(['itemsInCart']),
        },
        methods: {
            ...mapActions(['axiosPost', 'axiosGet']),
            async addToCart({ purchasableId, qty }) {
                await this.axiosPost({
                    mutation: 'SET_CART',
                    api: `${getCurrentLanguage()}/actions/commerce/cart/update-cart`,
                    data: {
                        purchasableId,
                        qty,
                    },
                });
            },
        },
        components: {
            Checkout: () =>
                import(
                    /* webpackChunkName: "Checkout" */ './vue/components/Checkout.vue'
                ),
            ProductDetail: () =>
                import(
                    /* webpackChunkName: "ProductDetail" */ './vue/components/ProductDetail.vue'
                ),
            Donation: () =>
                import(
                    /* webpackChunkName: "Donation" */ './vue/components/Donation.vue'
                ),
            Newsletter: () =>
                import(
                    /* webpackChunkName: "Newsletter" */ './vue/components/form/FormNewsletter.vue'
                ),
            Category: () =>
                import(
                    /* webpackChunkName: "Category" */ './vue/components/Category.vue'
                ),
            WheelForm: () =>
                import(
                    /* webpackChunkName: "WheelForm" */ './vue/components/form/FormWheelForm.vue'
                ),
            MailChimpForm: () =>
                import(
                    /* webpackChunkName: "MailChimp" */ './vue/components/form/FormMailChimp.vue'
                ),
            LoginRegister: () =>
                import(
                    /* webpackChunkName: "Login" */ './vue/components/LoginRegister.vue'
                ),
            Team: () =>
                import(
                    /* webpackChunkName: "Team" */ './vue/components/Team.vue'
                ),
        },
    });
    // fix issue when image is already in viewport and content is not loaded yet
    LazySizes.init();
};

// Execute async function
app()
    .then(() => {
        /**
         * Mobile Nav
         */
        const mobileNav = document.querySelector('.js-menu');
        if (mobileNav) {
            import(/* webpackChunkName: "mobileNav" */ './modules/nav')
                .then(module => module.default.init())
                .catch(e => console.error(`${e.name} : ${e.message}`));
        }
        /**
         * Headroom
         */
        const headroom = document.querySelector('.js-nav');
        if (headroom) {
            import(/* webpackChunkName: "headroom" */ './modules/headroom')
                .then(module => module.default.init())
                .catch(e => console.error(`${e.name} : ${e.message}`));
        }
        /**
         * Slider
         */
        const slider = document.querySelector('.js-slider');
        if (slider) {
            import(/* webpackChunkName: "slider" */ './modules/slider')
                .then(module => module.default.init())
                .catch(e => console.error(`${e.name} : ${e.message}`));
        }
        /**
         * Carousel
         */
        const carousel = document.querySelector('.js-carousel');
        if (carousel) {
            import(/* webpackChunkName: "carousel" */ './modules/carousel')
                .then(module => module.default.init())
                .catch(e => console.error(`${e.name} : ${e.message}`));
        }

        /**
         * Headline Border animation
         */
        const headlineBorder = document.querySelector('.js-step');
        if (headlineBorder) {
            import(
                /* webpackChunkName: "headlineBorder" */ './modules/scrollama'
            )
                .then(module => module.headlineBorder.init())
                .catch(e => console.error(`${e.name} : ${e.message}`));
        }
        /**
         * Mobile Hover on scroll animation
         */
        const hoverOnScroll =
            /Android|webOS|iPad|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent,
            ) && document.querySelector('.js-hover');
        if (hoverOnScroll) {
            import(
                /* webpackChunkName: "hoverOnScroll" */ './modules/scrollama'
            )
                .then(module => module.hoverOnScroll.init())
                .catch(e => console.error(`${e.name} : ${e.message}`));
        }
    })
    .catch(e => console.error(`${e.name} : ${e.message}`));

// accept HMR in dev

if (module.hot) {
    module.hot.accept();
}
if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-unused-expressions
    import(/* webpackChunkName: "debug" */ './vue/debug');
}
